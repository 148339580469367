import PropTypes from 'prop-types';
import { eventHref as eventHrefFunc } from 'SportsbookOverview/services';
import scores from './scores';
import headers from './headers';
import EventDetailView from './event_detail_view';

const EventDetailContainer = ({
  eventHref,
  sportEventPathId,
  meetingId,
  eventId,
  opponentADescription,
  opponentBDescription,
  metadata,
  showClock,
  showScoreboardHeaders,
  sportId,
  defaultInPlay,
  badgeFilter,
  // We cannot destructure the scoreboard into the props because it contains
  // properties as '1sA', '2sB', ... which are not valid when destructuring as
  // object keys
  scoreboard,
  // ---------------------------------------------------------------------
}) => {
  const [scoresA, scoresB] = scores(scoreboard);

  const {
    act,
    oaId,
    obId,
  } = scoreboard;

  return (
    <EventDetailView
      eventHref={eventHref || eventHrefFunc(sportEventPathId, meetingId, eventId)}
      opponentADescription={opponentADescription}
      opponentBDescription={opponentBDescription}
      scoresA={scoresA}
      scoresB={scoresB}
      opponentAActive={!!(act && act === oaId)}
      opponentBActive={!!(act && act === obId)}
      badges={(metadata ? (metadata.badges || []) : []).filter(badgeFilter)}
      showClock={showClock}
      eventId={eventId}
      defaultInPlay={defaultInPlay}
      sportId={sportId}
      showScoreboardHeaders={showScoreboardHeaders}
      headers={headers(scoreboard)}
    />
  );
};

EventDetailContainer.propTypes = {
  eventHref: PropTypes.string,
  sportEventPathId: PropTypes.number,
  meetingId: PropTypes.number,
  eventId: PropTypes.number.isRequired,
  opponentADescription: PropTypes.string.isRequired,
  opponentBDescription: PropTypes.string.isRequired,
  scoreboard: PropTypes.instanceOf(Object).isRequired,
  metadata: PropTypes.instanceOf(Object).isRequired,
  showClock: PropTypes.bool,
  defaultInPlay: PropTypes.bool,
  sportId: PropTypes.number.isRequired,
  showScoreboardHeaders: PropTypes.instanceOf(Object),
  badgeFilter: PropTypes.func,
};

EventDetailContainer.defaultProps = {
  eventHref: undefined,
  sportEventPathId: null,
  meetingId: null,
  showClock: false,
  defaultInPlay: false,
  showScoreboardHeaders: null,
  badgeFilter: () => true,
};

export default EventDetailContainer;
