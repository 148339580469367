import PropTypes from 'prop-types';
import { Link } from 'bv-components';
import ShowMore from '../common/show_more';

const EventDetailView = ({
  eventHref,
  opponentADescription,
  opponentBDescription,
  scoresA,
  scoresB,
  opponentAActive,
  opponentBActive,
  badges,
  eventId,
  showClock,
  defaultInPlay,
  showScoreboardHeaders,
  headers,
  sportId,
}) => (
  <Link to={eventHref} className={`inplay-coupon-competition-column inplay-sport-coupon-${sportId}`}>
    {showScoreboardHeaders && headers && (
      <span className="inplay-coupon-header-set-scores-headings">
        <span className="inplay-coupon-score-headers">
          {headers.map(
            (h) => <span key={h} className="inplay-coupon-score-label">{h}</span>,
          )}
        </span>
      </span>
    )}
    { /* Team A ---------------------------------------------------- */ }
    <span className="inplay-coupon-competition-team">
      <span className={`inplay-coupon-team-name ${opponentAActive ? 'active' : ''}`}>
        {opponentADescription}
      </span>
      <span className={`inplay-coupon-score-row inplay-coupon-header-set-scores ${opponentAActive ? 'active' : ''}`}>
        {scoresA.map((s) => <span className="inplay-coupon-score">{s}</span>)}
      </span>
    </span>
    { /* ----------------------------------------------------------- */ }

    { /* Team B ---------------------------------------------------- */ }
    <span className="inplay-coupon-competition-team">
      <span className={`inplay-coupon-team-name ${opponentBActive ? 'active' : ''}`}>
        {opponentBDescription}
      </span>
      <span className={`inplay-coupon-score-row inplay-coupon-header-set-scores ${opponentBActive ? 'active' : ''}`}>
        {scoresB.map((s) => <span className="inplay-coupon-score">{s}</span>)}
      </span>
    </span>
    { /* ----------------------------------------------------------- */ }
    <ShowMore
      showClock={showClock}
      defaultInPlay={defaultInPlay}
      eventId={eventId}
      badges={badges}
    />
  </Link>
);

EventDetailView.propTypes = {
  eventHref: PropTypes.string.isRequired,
  opponentADescription: PropTypes.string.isRequired,
  opponentBDescription: PropTypes.string.isRequired,
  scoresA: PropTypes.instanceOf(Array).isRequired,
  scoresB: PropTypes.instanceOf(Array).isRequired,
  opponentAActive: PropTypes.bool.isRequired,
  opponentBActive: PropTypes.bool.isRequired,
  badges: PropTypes.instanceOf(Array).isRequired,
  eventId: PropTypes.number.isRequired,
  showClock: PropTypes.bool,
  defaultInPlay: PropTypes.bool,
  headers: PropTypes.isRequired,
  showScoreboardHeaders: PropTypes.bool,
  sportId: PropTypes.number.isRequired,
};

EventDetailView.defaultProps = {
  showClock: false,
  defaultInPlay: false,
  showScoreboardHeaders: false,
};

export default EventDetailView;
