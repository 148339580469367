import PropTypes from 'prop-types';
import { eventHref as eventHrefFunc } from 'SportsbookOverview/services';
import EventDetailView from './event_detail_view';

const EventDetailContainer = ({
  defaultInPlay,
  sportEventPathId,
  meetingId,
  eventId,
  opponentADescription,
  opponentBDescription,
  eventHref,
  showMore,
  showClock,
  metadata,
  badgeFilter,
  inPlay,
  scoreboard: {
    scrA,
    scrB,
    redCardA,
    redCardB,
    act,
    oaId,
    obId,
  },
}) => (
  <EventDetailView
    eventHref={eventHref || eventHrefFunc(sportEventPathId, meetingId, eventId)}
    opponentADescription={opponentADescription}
    opponentBDescription={opponentBDescription}
    opponentAScore={scrA}
    opponentBScore={scrB}
    opponentARedCards={redCardA || 0}
    opponentBRedCards={redCardB || 0}
    opponentAActive={!!(act && act === oaId)}
    opponentBActive={!!(act && act === obId)}
    showMore={showMore}
    badgeFilter={badgeFilter}
    badges={metadata?.badges || []}
    inPlay={defaultInPlay || inPlay}
    defaultInPlay={defaultInPlay}
    eventId={eventId}
    showClock={showClock}
  />
);

EventDetailContainer.propTypes = {
  sportEventPathId: PropTypes.number,
  meetingId: PropTypes.number,
  eventId: PropTypes.number.isRequired,
  opponentADescription: PropTypes.string.isRequired,
  opponentBDescription: PropTypes.string.isRequired,
  scoreboard: PropTypes.instanceOf(Object).isRequired,
  defaultInPlay: PropTypes.bool,
  inPlay: PropTypes.bool.isRequired,
  showMore: PropTypes.bool,
  eventHref: PropTypes.string,
  showClock: PropTypes.bool,
  metadata: PropTypes.instanceOf(Object).isRequired,
  badgeFilter: PropTypes.func,
};

EventDetailContainer.defaultProps = {
  showMore: true,
  eventHref: undefined,
  sportEventPathId: null,
  meetingId: null,
  defaultInPlay: false,
  showClock: false,
  badgeFilter: () => true,
};

export default EventDetailContainer;
